// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  position: fixed;
  bottom: 5%;
  width: 100%;
}

.App {
  text-align: center;
}

.data-area-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly
}

.navbarButton {
  color: #fff !important;
  padding: .5rem 1rem !important;
}

.navbarStyle {
  padding: .5rem 1rem !important;
}

.table-responsive {
  max-height: 39rem !important;
}

.tableColumn {
  word-break: break-all
}

.profileButton {
  margin: .5rem .5rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB;AACF;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE;AACF;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["footer {\n  position: fixed;\n  bottom: 5%;\n  width: 100%;\n}\n\n.App {\n  text-align: center;\n}\n\n.data-area-div {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly\n}\n\n.navbarButton {\n  color: #fff !important;\n  padding: .5rem 1rem !important;\n}\n\n.navbarStyle {\n  padding: .5rem 1rem !important;\n}\n\n.table-responsive {\n  max-height: 39rem !important;\n}\n\n.tableColumn {\n  word-break: break-all\n}\n\n.profileButton {\n  margin: .5rem .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
