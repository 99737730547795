import { AuthenticatedTemplate } from '@azure/msal-react';
import { NavigationBar } from './NavigationBar';


const handleButtonClick = (agency) => {
    const returnURL = window.location.href + '?agency=' + agency;
    const vokUrl = new URL("https://dev.verify.ok.gov");
    // const vokUrl = new URL("http://localhost:3006")
    vokUrl.searchParams.append("return_url", returnURL);
    vokUrl.searchParams.append("after_verify_url", "http://oklahoma.gov");
    window.location.href=vokUrl.href;
  };


export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar />
            <br />
            <h5>
                <center>Welcome to this Example Oklahoma Citizen Web Site</center>
            </h5>
            <br></br>
            <h5>
                <center>
                    <button onClick={() => handleButtonClick('DHS')}>
                        Verify Online (from OKDHS site)
                    </button>
                </center>
            </h5>
            <h5>
                <center>
                    <button onClick={() => handleButtonClick('OESC')}>
                        Verify Online (from OESC)
                    </button>
                </center>
            </h5>
            <br />
            


            {props.children}
            <br />
            <AuthenticatedTemplate>
                <footer>
                    <center>
                        You are logged in
                    </center>
                </footer>
            </AuthenticatedTemplate>
        </>
    );
};

function gotoLink(link){
    window.open(link.value)
};


  

  